import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';
import './customcomponent.css';

const SliderHeroSection = ({ backgroundImage, title, subtitle, textColor, isDefault,featureTitle,featureSubtitle,imageUrl,showImage,desc }) => {
  return (
    <div
      className="Slider_hero-section" // Add vh-100 class here
      style={{ 
        backgroundColor: backgroundImage,
        color: textColor // Set text color
      }}
    >
      <Container fluid className=" d-flex justify-content-center align-items-center">
       
          
      {isDefault ? (
  <>
    <div className='SliderContainer'>
      <Container>
        <Row className='justify-content-center'>
          <Col md={6} lg={6}>
            <h1 className='SliderHeader'>
              {featureTitle.includes("Trivont") ? (
                <>
                  {featureTitle.split("Trivont").map((part, index) => (
                    <span style={{ color: textColor || '#242424' }} key={index}>
                      {part}
                      {index !== featureTitle.split("Trivont").length - 1 && (
                        <span style={{ fontWeight: 'bold', color:'#2196f3' }}>Trivont </span>
                      )}
                    </span>
                  ))}
                </>
              ) : (
                featureTitle
              )}
            </h1>
            <p style={{
              fontSize:'20px'
            }} className=''>{featureSubtitle}</p>
          </Col>
          {showImage && ( // Render the image only if showImage is true
            <Col md={6} lg={6}>
              <img src={imageUrl} alt="Description" />
            </Col>
          )}
        </Row>
      </Container>
    </div>
  </>
) : (
  // Render the alternate component if isDefault is false
  <>
     <Container className="d-flex flex-column justify-content-center align-items-center slideHeroSection" >
      <Row className="w-100">
        <Col className="d-flex flex-column justify-content-center align-items-center">
          <h1 className="text-center" style={{ color: textColor }}>{title}</h1> {/* Title */}
          <p className="h5 text-center">{subtitle}</p> {/* Subtitle */}
          <p className="text-center">{desc}</p> {/* Description */}
        </Col>
      </Row>
    </Container>
  </>
)}

      </Container>
    </div>
  );
};

export default SliderHeroSection;
