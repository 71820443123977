import React, { useContext } from 'react'
import Navbar from '../components/Navbar'
import { DataContext } from '../context'
import Footer from '../components/Footer';
import BSsection4s from '../components/BSsection4';

import SliderHeroSection from '../components/customComponents/SliderHeroSection';
import AnimatedCardGrid from '../components/customComponents/animatedGrid';
import BannerSection from '../components/customComponents/bannerSection';
import IconBox from '../components/iconbox';
import MigrationJumbotron from '../components/customComponents/animatedJumbtro';
import gear from '../images/icons/gear.png'
import asset from '../images/icons/assset.png'
import Outsourcing from '../images/icons/outsourcing.png';
import infra from '../images/carouselImages/3628748_628.jpg'
const IaaS = () => {
    const context = useContext(DataContext);
    const {footer,VMservice } = context.allData
  
    const { handleSearch, handleOffcanvas } = context;
    
    const ITManagementData = [
        {
          title: "Remote Managed Services",
          subtitle: "Efficient Support Wherever You Are",
          image:gear,
          description: "Experience seamless support for your IT infrastructure, even from remote locations. Our remote managed services offer continuous assistance, backup archiving, and more."
        },
        {
          title: "IT Asset Management Services",
          subtitle: "Proactive Management for Enhanced Productivity",
         image:asset,
          description: "Optimize productivity and manageability through proactive IT asset management. Reduce unnecessary efforts and redirect resources to core business operations."
        },
        {
          title: "Outsourcing Skilled Resources",
          subtitle: "Unlocking the Power of Expertise",
          image:Outsourcing,
          description: "Confidently outsource skilled resources with up-to-date IT service management skills. Gain complete control over your IT operations and leverage experienced resources for maximum benefit."
        },
      ];
      
    
    
    
    return (
    <>
    <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True"  />
    {/* <Banner2 data={ hero2Data}/> */}
    
    <BannerSection
          title="Infrastructure Managed Services"
          backgroundColor="linear-gradient(90deg, hsla(41, 100%, 70%, 1) 0%, hsla(7, 76%, 47%, 1) 100%)"
          color="#ffff"
          height="400px"
          backgroundImage={infra}
          customBackgroundSize='67rem'
        />
        
  
  <SliderHeroSection backgroundImage='#ffff'  title="Automation & AI" subtitle="Simplify your IT operations" 
    textColor='#242424' isDefault={true} featureTitle='Elevating IT Excellence: A Holistic Approach to Managed Services'
     featureSubtitle="Focusing on Lifecycle Management and Customer Engagement Optimization .  we diligently manage IT services to ensure seamless operations. This commitment includes leveraging advanced service desks that integrate social and cutting-edge technologies. Central to our strategy is Infrastructure Managed Services, ensuring comprehensive support across your IT infrastructure." >
      
    </SliderHeroSection>
   
    <MigrationJumbotron title='Efficient IT Infrastructure Optimization' description='Streamlining Operations for Enhanced Performance' />  
    
  
     <IconBox items={ ITManagementData}/> 
  
      
  
  
  
      <Footer data={footer} />
    
    </>
    )
}

export default IaaS